import React, { useState } from 'react';

export const GeneralContext = React.createContext({});

export const GeneralContextProvider = props => {
    // states for search
    const [forceUpdate, setForceUpdate] = useState(0);
    const [isSelected, setIsSelected] = useState([]);
    const [isSelectedFilter, setIsSelectedFilter] = useState([]);
    const [values, setValues] = useState({
        tags: [],
        content: [],
        categories: [],
        initialDate: null,
        endDate: null,
        order: { name: 'Mais recentes', value: ['published_at', 'desc'] }
    });
    const [clearAll, setClearAll] = useState(false);
    const [wasActivated, setWasActivated] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        content: false,
        date: false
    });

    // states for "Achado"
    const [filters, setFilters] = useState({
        themes: [],
        contents: [],
        areas: [],
        formats: [],
        years: []
    });
    const [clearAllFilters, setClearAllFilters] = useState(false);

    // used to not repeat news in page
    const [displayedNews, setDisplayedNews] = useState({});

    // states for tutorial context
    const [hasInputValue, setHasInputValue] = useState(false);
    const [hasResult, setHasResult] = useState(false);

    //check if editor is open
    const [isStoryblokEditor, setIsStoryblokEditor] = useState(false);

    return (
        <GeneralContext.Provider
            value={{
                isSelectedFilter,
                setIsSelectedFilter,
                values,
                setValues,
                forceUpdate,
                setForceUpdate,
                clearAll,
                setClearAll,
                selectedOptions,
                setSelectedOptions,
                wasActivated,
                setWasActivated,
                hasInputValue,
                setHasInputValue,
                hasResult,
                setHasResult,
                isStoryblokEditor,
                setIsStoryblokEditor,
                displayedNews,
                setDisplayedNews,
                filters,
                setFilters,
                clearAllFilters,
                setClearAllFilters,
                isSelected,
                setIsSelected
            }}
        >
            {props.children}
        </GeneralContext.Provider>
    );
};

export const useFilter = () => React.useContext(GeneralContext);
export const useTutorial = () => React.useContext(GeneralContext);
export const useAchadoFilter = () => {
    const context = React.useContext(GeneralContext);
    const {
        filters,
        setFilters,
        clearAllFilters,
        setClearAllFilters,
        forceUpdate,
        setForceUpdate,
        isSelected,
        setIsSelected
    } = context;
    return {
        filters,
        setFilters,
        clearAllFilters,
        setClearAllFilters,
        forceUpdate,
        setForceUpdate,
        isSelected,
        setIsSelected
    };
};

export function useEditor() {
    const context = React.useContext(GeneralContext);
    const { isStoryblokEditor, setIsStoryblokEditor } = context;
    return { isStoryblokEditor, setIsStoryblokEditor };
}

export function useDisplayedNews() {
    const context = React.useContext(GeneralContext);

    const { displayedNews, setDisplayedNews } = context;
    return { displayedNews, setDisplayedNews };
}
