const Colors = {
    primary: '#E6F805',
    primaryHover: '#BFD000',
    black: '#000000',
    white: '#FFFFFF',
    lightGrey: '#EEEEEE',
    grey: '#909090',
    darkGrey: '#383838',
    red: '#C62222',
    orange: '#FF6809',
    blue: '#007BBB',
    green: '#00CA00',
    educationGreen: '#19DCA5',
    educationOrange: '#FF6C41',
    educationYellow: '#FFB900',
    educationBlue: '#0082FA',
    purple: '#CB24D3',
    beige: '#B08A79',
    brown: '#A45026',
    pink: '#FF5AB1',
    darkPink: '#5F2E43',
    yellow: '#F4BA00'
};

export default Colors;
