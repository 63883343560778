import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, concat, HttpLink } from '@apollo/client';
import { token } from '_config';
import { GeneralContextProvider } from 'providers/generalContext';
import { PlansProvider } from 'contexts/PlansContext';
import { UserProvider } from 'contexts';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import StoryblokClient from 'storyblok-js-client';

ReactGA.initialize('G-JFVNKL96HB');

export const Storyblok = new StoryblokClient({
    accessToken: token,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
});

const httpLink = new HttpLink({ uri: 'https://gapi.storyblok.com/v1/api' });

const preview = window.location.search.includes('_storyblok') || window.location.href.includes('http://localhost:300');

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            token: token,
            version: preview ? 'draft' : 'published'
        }
    }));
    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink)
});

export const contextClient = new ApolloClient({
    cache: new InMemoryCache(),
    uri: process.env.REACT_APP_GRAPHQL_API_URL
});

require('dotenv').config();

if (process.env.REACT_APP_NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://992cc460aa18417681a9a58af561381d@o1060428.ingest.sentry.io/6383415',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <GeneralContextProvider>
                <UserProvider>
                    <PlansProvider>
                        <App />
                    </PlansProvider>
                </UserProvider>
            </GeneralContextProvider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
