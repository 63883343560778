import React, { createContext } from 'react';
import usePlans from './hooks/usePlans';

const PlansContext = createContext({
    selectedPlan: {},
    setSelectedPlan: () => {},
    wantsDonate: false,
    setWantsDonate: () => {},
    price: '',
    setPrice: () => {},
    isMonthly: true,
    setIsMonthly: () => {},
    customPlan: {},
    plansList: {},
    getCardHash: () => {},
    handlePlanValue: () => {}
});

const PlansProvider = ({ children }) => {
    const {
        selectedPlan,
        setSelectedPlan,
        customPlan,
        plansList,
        getCardHash,
        handlePlanValue,
        wantsDonate,
        setWantsDonate,
        price,
        setPrice,
        isMonthly,
        setIsMonthly
    } = usePlans();

    return (
        <PlansContext.Provider
            value={{
                selectedPlan,
                setSelectedPlan,
                customPlan,
                plansList,
                getCardHash,
                handlePlanValue,
                wantsDonate,
                setWantsDonate,
                price,
                setPrice,
                isMonthly,
                setIsMonthly
            }}
        >
            {children}
        </PlansContext.Provider>
    );
};

export { PlansContext, PlansProvider };
