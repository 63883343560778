import { useCallback, useState } from 'react';

const useUser = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('LP_user')) || null);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('LP_token')) || null);

    const resetUser = useCallback(() => {
        localStorage.clear();
        setUser(null);
        setToken(null);
    }, []);

    return { user, setUser, token, setToken, resetUser };
};

export default useUser;
