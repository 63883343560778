import styled from 'styled-components';
import { Lottie } from '@alfonmga/react-lottie-light-ts';
import Colors from 'components/tokens/Colors';
import animation from './assets/yellow-splash-screen.json';

function SplashScreen() {
    const animationData = animation;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <PageLoaderContainer className="splash-screen">
            <Lottie config={defaultOptions} style={{ margin: '0' }} />
        </PageLoaderContainer>
    );
}

const PageLoaderContainer = styled.div`
    align-items: center;
    background-color: ${Colors.primary};
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: 9999;
    top: 0;
`;

export default SplashScreen;
