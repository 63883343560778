import React, { createContext } from 'react';
import useUser from './hooks/useUser';

const UserContext = createContext({
    user: {},
    setUser: () => {},
    token: {},
    setToken: () => {}
});

const UserProvider = ({ children }) => {
    const { user, setUser, token, setToken, resetUser } = useUser();

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                token,
                setToken,
                resetUser
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
