import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { contextClient } from 'index';
import { UserContext } from 'contexts/UserContext';
import axios from 'axios';
const { REACT_APP_PAGARME_TOKEN } = process.env;

const query = gql`
    query {
        PlanItems {
            items {
                name
                id
                url
                interval
                interval_count
                payment_methods
                minimum_price
                status
                currency
                billing_type
                key
            }
        }
    }
`;

const usePlans = () => {
    const [selectedPlan, setSelectedPlan] = useState(JSON.parse(localStorage.getItem('LP_selectedPlan')) || {});
    const [plansList, setPlansList] = useState({});
    const [customPlan, setCustomPlan] = useState({});
    const [wantsDonate, setWantsDonate] = useState(false);
    const [price, setPrice] = useState('');
    const [isMonthly, setIsMonthly] = useState(true);
    const { error, data } = useQuery(query, { client: contextClient });
    const { user } = useContext(UserContext);

    const handlePlanValue = useCallback(
        planId => {
            let plan = Object?.values(plansList)
                ?.flatMap(item => item)
                ?.filter(plan => plan.id === planId)?.[0];

            if (plan?.minimum_price) {
                return (plan?.minimum_price * 0.01)?.toFixed(2)?.replace('.', ',') || '';
            }

            return '';
        },
        [plansList]
    );

    useEffect(() => {
        if (data) {
            setPlansList(groupBy(data?.PlanItems?.items, 'name'));
            setCustomPlan(groupByCustom(data?.PlanItems?.items, 'name')?.Personalizado);
        }
    }, [data]);

    if (error) return console.error('Error to get plans: ' + error);

    function groupBy(objArray, prop) {
        return [...objArray]
            .sort((a, b) => (a[prop].split(' ')[1] < b[prop].split(' ')[1] ? 1 : -1))
            .sort((a, b) => (a[prop].split(' ')[0] < b[prop].split(' ')[0] ? 1 : -1))
            .reduce((acc, obj) => {
                let key = obj[prop].split(' ')[0];
                if (key === 'Anual' || key === 'Mensal') return acc;

                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(obj);
                return acc;
            }, {});
    }

    function groupByCustom(objArray, prop) {
        return objArray.reduce((acc, obj) => {
            let key = obj[prop].split(' ')[1];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    async function getCardHash(cardData) {
        const getCard = () => {
            const card = {};
            let expirationDate = cardData.validateDate;
            card.number = cardData.creditCard.replace(/ /g, '');
            card.holder_name = cardData.name;
            card.exp_month = expirationDate.split('/')[0];
            card.exp_year = expirationDate.split('/')[1];
            card.cvv = cardData.securityCode;
            card.card_cvv = cardData.securityCode;
            card.billing_address = {
                line_1: `${user?.location?.street || user.street}, ${user?.location?.number || user.number}, ${
                    user?.location?.neighborhood || user.neighborhood
                }`,
                zip_code: user?.location?.cep?.replace?.(/\D+/g, '') || user.cep?.replace?.(/\D+/g, ''),
                city: user?.location?.city || user.city,
                state: user?.location?.state || user.state,
                country: 'BR'
            };
            return card;
        };

        const options = {
            method: 'POST',
            url: 'https://api.pagar.me/core/v5/tokens',
            params: { appId: REACT_APP_PAGARME_TOKEN },
            headers: { 'Content-Type': 'application/json' },
            data: {
                type: 'card',
                card: getCard()
            }
        };

        const { data } = await axios.request(options).catch(e => console.error(e));
        return data;
    }

    return {
        selectedPlan,
        setSelectedPlan,
        customPlan,
        plansList,
        setPlansList,
        setCustomPlan,
        getCardHash,
        handlePlanValue,
        wantsDonate,
        setWantsDonate,
        price,
        setPrice,
        isMonthly,
        setIsMonthly
    };
};

export default usePlans;
